<template>
  <v-container>
    <custom-banner
        title=""
        :image="require('@/assets/imgs/desing/work.jpeg')"
    />

    <br>
    <h1>{{ $t('join_our_team') }}</h1>
    <br>
    <div>
    <span v-for="(line, index) in $t('join_as_barista')" :key="'barista' + index"
          style="display: block; line-height: 1.4;">
        {{ line }}
    </span>
    </div>

  </v-container>
</template>
<script>
import CustomBanner from '@/components/core/CustomBanner.vue'

export default {
  components: {CustomBanner},

}
</script>
<style lang="">

</style>