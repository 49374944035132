import Vue from 'vue';
import './plugins/base';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import {VueMaskDirective} from 'v-mask';
import VueI18n from 'vue-i18n';
import {LANGUAGE_TEXT} from "./language/language";

Vue.config.productionTip = false;
Vue.use(VueI18n);



async function detectUserLanguage() {
    try {
        const response = await fetch("https://ipapi.co/json/");

        const data = await response.json();

        const countryCode = data.country_code || "TR";
        let detectedLocale = "tr";

        if (countryCode === "DE") {
            detectedLocale = "de";
        } else if (countryCode !== "TR" && countryCode !== "DE") {
            detectedLocale = "en";
        }

        localStorage.setItem("locale", detectedLocale);
        return detectedLocale;
    } catch (error) {
        localStorage.setItem("locale", "tr");
        return "tr";
    }
}

(async () => {
    let savedLocale = localStorage.getItem("locale");

    if (!savedLocale) {
        savedLocale = await detectUserLanguage();
    }

    const i18n = new VueI18n({
        locale: savedLocale,
        fallbackLocale: "tr",
        messages: LANGUAGE_TEXT
    });

    Vue.directive("mask", VueMaskDirective);

    new Vue({
        router,
        store,
        vuetify,
        i18n,
        render: h => h(App)
    }).$mount("#app");
})();