<template>
  <v-container>

    <custom-banner
        :image="require('@/assets/imgs/desing/missionbanner.jpeg')"
    />

    <div>
      <h1>{{ $t('brand_mission') }}</h1>

      <span v-for="(line, index) in $t('straborn_coffee_mission')" :key="'mission-' + index"
            style="display: block; line-height: 1.4;">
        {{ line }}
    </span>

      <h1>{{ $t('brand_vision') }}</h1>

      <span v-for="(line, index) in $t('straborn_coffee_vision')" :key="'vision-' + index"
            style="display: block; line-height: 1.4;">
        {{ line }}
    </span>
      <span v-for="(line, index) in $t('straborn_coffee_services')" :key="'straborn_services-' + index"
            style="display: block; line-height: 1.4;">
        {{ line }}
    </span>
    </div>

  </v-container>
</template>
<script>
import CustomBanner from '@/components/core/CustomBanner.vue'

export default {
  components: {CustomBanner},

}
</script>
<style lang="">

</style>