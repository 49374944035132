<template>
  <v-app-bar app flat color="primary">
    <v-container>
      <v-row align="center">
        <v-img
            :src="require('@/assets/logo_transparent.png')"
            class="logo"
            cover
            height="42"
            width="42"
            max-width="42"
            @click="$vuetify.goTo(0)"
        />

        <v-btn class="hidden-sm-and-down" text to="/" color="white">
          {{ $t('home') }}
        </v-btn>

<!--        <v-btn class="hidden-sm-and-down" text to="/products" color="white">-->
<!--          {{ $t('menu') }}-->
<!--        </v-btn>-->

<!--        <v-btn class="hidden-sm-and-down" text to="/photoGallary" color="white">-->
<!--          {{ $t('gallery') }}-->
<!--        </v-btn>-->

        <v-menu rounded open-on-hover offset-y transition="slide-x-transition" bottom right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text color="white" v-bind="attrs" v-on="on">
              {{ $t('about_us') }}
            </v-btn>
          </template>

          <v-list nav dense>

            <!--                      <v-list-item link to="/products">-->
            <!--                        <v-list-item-icon>-->
            <!--                          <v-icon>mdi-widgets</v-icon>-->
            <!--                        </v-list-item-icon>-->
            <!--                        <v-list-item-content>-->
            <!--                          <v-list-item-title>{{$t('menu')}}</v-list-item-title>-->
            <!--                        </v-list-item-content>-->
            <!--                            <v-icon>mdi-menu-right</v-icon>-->
            <!--                      </v-list-item>-->

            <!--        <v-list-item link to="/photoGallary">-->
            <!--            <v-list-item-icon>-->
            <!--              <v-icon>mdi-file-outline</v-icon>-->
            <!--            </v-list-item-icon>-->
            <!--            <v-list-item-content>-->
            <!--              <v-list-item-title>{{$t('gallery')}}</v-list-item-title>-->
            <!--            </v-list-item-content>-->
            <!--                <v-icon>mdi-menu-right</v-icon>-->
            <!--          </v-list-item>-->


            <v-list-item-group>
              <v-list-item link to="/about-story">
                <v-list-item-icon>
                  <v-icon>mdi-book-open-page-variant</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('about_us_lower') }} - {{ $t('our_story') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item link to="/mission-vision">
                <v-list-item-icon>
                  <v-icon>mdi-bullseye-arrow</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('brand_mission_vision') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item link to="/our-branches">
                <v-list-item-icon>
                  <v-icon>mdi-map-marker</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('branches_lower') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item link to="/invitation-page">
                <v-list-item-icon>
                  <v-icon>mdi-email-open-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('invitation') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item link to="/contact-page">
                <v-list-item-icon>
                  <v-icon>mdi-phone-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('contact') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item link to="/work-with-us">
                <v-list-item-icon>
                  <v-icon>mdi-account-group-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('join_our_team') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item link to="/straborn-page">
                <v-list-item-icon>
                  <v-icon>mdi-earth</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> {{ $t('strabon_lower') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

            </v-list-item-group>



<!--            <v-list-item link to="/about-story">-->
<!--              <v-list-item-icon>-->
<!--                <v-icon>mdi-angularjs</v-icon>-->
<!--              </v-list-item-icon>-->
<!--              <v-list-item-content>-->
<!--                <v-list-item-title>{{ $t('about_us_lower') }} - {{ $t('our_story') }}</v-list-item-title>-->
<!--              </v-list-item-content>-->
<!--              <v-icon>mdi-menu-right</v-icon>-->
<!--            </v-list-item>-->



            <!--          <v-list-item link to="/sales-agreement">-->
            <!--            <v-list-item-icon>-->
            <!--              <v-icon>mdi-file</v-icon>-->
            <!--            </v-list-item-icon>-->
            <!--            <v-list-item-content>-->
            <!--              <v-list-item-title>Mesafeli Satış Sözleşmesi</v-list-item-title>-->
            <!--            </v-list-item-content>-->
            <!--                <v-icon>mdi-menu-right</v-icon>-->
            <!--          </v-list-item>-->
            <!--          <v-list-item link to="/cancellation-policy">-->
            <!--            <v-list-item-icon>-->
            <!--              <v-icon>mdi-file</v-icon>-->
            <!--            </v-list-item-icon>-->
            <!--            <v-list-item-content>-->
            <!--              <v-list-item-title>İptal İade Şartnamesi</v-list-item-title>-->
            <!--            </v-list-item-content>-->
            <!--                <v-icon>mdi-menu-right</v-icon>-->
            <!--          </v-list-item>-->
            <!--          <v-list-item link to="/security-policy">-->
            <!--            <v-list-item-icon>-->
            <!--              <v-icon> mdi-access-point-check </v-icon>-->
            <!--            </v-list-item-icon>-->
            <!--            <v-list-item-content>-->
            <!--              <v-list-item-title>Gizlilik ve Güvenlik Politikası</v-list-item-title>-->
            <!--            </v-list-item-content>-->
            <!--                <v-icon>mdi-menu-right</v-icon>-->
            <!--          </v-list-item>-->

          </v-list>
        </v-menu>

        <v-spacer/>

        <div class="language-container">
          <v-menu offset-y open-on-hover transition="slide-y-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-img :src="getCurrentFlag" class="flag-icon"/>
              </v-btn>
            </template>

            <v-list>
              <v-list-item v-for="(flag, locale) in filteredFlags" :key="locale" @click="changeLocale(locale)">
                <v-list-item-avatar>
                  <v-img :src="flag" class="flag-icon" contain/>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ getLanguageName(locale) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-row>
    </v-container>


    <!--      <div v-if="getToken==''||getToken==null">-->
    <!--      -->
    <!--        <v-btn color="white" class="mr-2" to="/Security" icon>-->
    <!--            <v-icon>mdi-login</v-icon>-->
    <!--        </v-btn>-->
    <!--      </div>-->
    <!--      <div v-else>-->
    <!--        <v-btn color="white" to="/profile" icon>-->
    <!--          <v-icon>mdi-account-circle</v-icon>-->
    <!--        </v-btn>-->
    <!--        <v-btn color="white" class="mr-2" to="/basket" icon>-->
    <!--            <v-icon>mdi-cart</v-icon>-->
    <!--        </v-btn>-->
    <!--        <v-btn color="white" class="mr-2" to="/orders" icon>          -->
    <!--           <v-icon>mdi-truck</v-icon>-->
    <!--        </v-btn>-->
    <!--        <v-btn color="white" class="mr-2" @click="logOut" icon>-->
    <!--           <v-icon>mdi-logout</v-icon>-->
    <!--        </v-btn>-->
    <!--      </div>    -->


  </v-app-bar>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex"; // mapGetters kaldırıldı

export default {
  data() {
    return {
      flags: {
        tr: require("@/assets/flags/tr.png"),
        de: require("@/assets/flags/de.png"),
        en: require("@/assets/flags/uk.png"),
      },
    };
  },
  computed: {
    ...mapGetters({
      getToken: 'getAppToken',//kullanıcı bilgilri de alınacak ve giriş ve sapaet sipariş sayıları güncellenecek created içerisinde
      loginLoading: "getLoginLoading",
    }),
    getCurrentFlag() {
      return this.flags[this.$i18n.locale] || this.flags.tr;
    },
    filteredFlags() {
      return Object.fromEntries(Object.entries(this.flags).filter(([key]) => key !== this.$i18n.locale));
    },
  },
  methods: {
    ...mapMutations({
      logOut: "logOut",
    }),
    ...mapActions({
      autoLogin: "autoLogin",
    }),
    changeLocale(locale) {
      localStorage.setItem("locale", locale);
      this.$i18n.locale = locale;
    },
    getLanguageName(locale) {
      const languages = {tr: "Türkçe", de: "Deutsch", en: "English"};
      return languages[locale] || "Unknown";
    },
  },
};
</script>

<style scoped>
/* Navbar */
.v-app-bar {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  position: relative;
}

.navbar-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  max-height: 40px;
  max-width: 100px;
  width: auto;
  height: auto;
  margin-left: 0px;
  padding-left: 10px;
}

.language-container {
  position: fixed;
  right: 20px;
  top: 10px;
  z-index: 1100;
}

.flag-icon {
  width: 24px;
  height: 48px;
}

@media (max-width: 768px) {
  .menu-items {
    flex-direction: column;
    align-items: flex-start;
  }

  .language-buttons {
    position: static;
    margin-left: auto;
  }
}
</style>