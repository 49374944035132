<template>
  <v-container>
    <base-subheading>{{ $t('branches') }}</base-subheading>

    <custom-banner :image="require('@/assets/imgs/desing/aboutbanner.jpeg')"/>
    <br>

    <v-row>
      <v-col v-for="(branch, index) in filteredBranches" :key="index" cols="12" md="4" sm="6">
        <v-card class="branch-card" elevation="3">
          <v-img
              :src="require('@/assets/imgs/branches/' + branch.image)"
              class="branch-image"
          ></v-img>
          <v-card-title class="text-center">{{ branch.name }}</v-card-title>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import CustomBanner from '@/components/core/CustomBanner.vue'

export default {
  components: {CustomBanner},

  data() {
    return {
      allBranches: [
        {name: 'Atatürk Kültür Merkezi', image: 'akm.jpg', locale: 'tr'},
        {name: 'Kızılay Saraçoğlu', image: 'saracoglu.jpg', locale: 'tr'},
        {name: 'Milli Kütüphane', image: 'milli_kutuphane.jpg', locale: 'tr'},
        {name: 'Etlik Şehir Hastanesi - Genel', image: 'etlik_genel.jpg', locale: 'tr'},
        {name: 'Etlik Şehir Hastanesi - Ortopedi', image: 'etlik_ortopedi.jpg', locale: 'tr'},
        {name: 'Etlik Şehir Hastanesi - Kalp Damar', image: 'etlik_kalp.jpg', locale: 'tr'},
        {name: 'Koblenz Hbf', image: 'saracoglu.jpg', locale: 'de'},
        {name: 'Aachen Hbf (Soon)', image: 'saracoglu.jpg', locale: 'de'},
        {name: 'Koblenz Hbf', image: 'saracoglu.jpg', locale: 'en'},
        {name: 'Aachen Hbf (Soon)', image: 'saracoglu.jpg', locale: 'en'}
      ]
    }
  },

  computed: {
    filteredBranches() {
      return this.allBranches.filter(branch => branch.locale === this.$i18n.locale);
    }
  }
}
</script>

<style scoped>
.branch-card {
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.branch-card:hover {
  transform: scale(1.05);
}

.branch-image {
  object-fit: cover !important;
  width: 100%;
  height: 250px;
  max-height: 250px;
}
</style>
