<template>
  <v-container>
    <custom-banner
        :image="require('@/assets/imgs/desing/invate.jpeg')"
    />

    <br>
    <h1>{{ $t('contact') }}</h1>
    <br>
    <div>
    <span v-for="(line, index) in $t('address')" :key="'address' + index"
          style="display: block; line-height: 1.4;">
        {{ line }}
    </span>
    </div>

  </v-container>
</template>
<script>
import CustomBanner from '@/components/core/CustomBanner.vue'

export default {
  components: {CustomBanner},

}
</script>
<style lang="">

</style>