<template>
  <v-container>
    <custom-banner
        title=""
        :image="require('@/assets/imgs/desing/Straborn.png')"
    />
    <br>
    <h1>  {{ $t('strabon') }}</h1>

    <div>
    <span v-for="(line, index) in $t('about_strabon')" :key="'strabon-' + index" style="display: block; line-height: 1.4;">
        {{ line }}
    </span>
      <br>
      <span v-for="(line, index) in $t('strabon_biography')" :key="'biography-' + index" style="display: block; line-height: 1.4;">
        {{ line }}
    </span>

    </div>

  </v-container>
</template>

<script>
import CustomBanner from '@/components/core/CustomBanner.vue'

export default {
  components: {CustomBanner},
}
</script>

<style lang="">
</style>