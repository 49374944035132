<template>
  <v-container
      id="about"
      tag="section"
  >
    <base-subheading>{{ $t('our_story_about_us') }}</base-subheading>

    <div>
      <span v-for="(line, index) in $t('straborn_story')" :key="'story-' + index" style="display: block; line-height: 1.4;">
        {{ line }}
    </span>
      <br>
      <span v-for="(line, index) in $t('straborn_coffee_intro')" :key="'intro-' + index" style="display: block; line-height: 1.4;">
        {{ line }}
    </span>
    </div>
    <br>
    <base-subheading>{{ $t('brand_mission') }}</base-subheading>

    <div>
    <span v-for="(line, index) in $t('straborn_coffee_mission')" :key="'mission-' + index" style="display: block; line-height: 1.4;">
        {{ line }}
    </span>
      <br>
      <span v-for="(line, index) in $t('straborn_coffee_services')" :key="'services-' + index" style="display: block; line-height: 1.4;">
        {{ line }}
    </span>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'HomeAbout'
}
</script>

<style>

</style>