<template>
  <v-container>
    <base-subheading>{{ $t('our_story_about_us') }}</base-subheading>

    <custom-banner
        :image="require('@/assets/imgs/desing/aboutbanner.jpeg')"
    />
    <br>

    <div>
      <span v-for="(line, index) in $t('straborn_coffee_quality')" :key="'quality' + index"
            style="display: block; line-height: 1.4;">
        {{ line }}
    </span>
    </div>
    <br>

    <v-row>
      <v-col cols="12" md="6" sm="12">
        <v-img
            :src="require('@/assets/imgs/desing/aboutsubbanner.jpeg')"
            class="grey lighten-2"
            height="400"
            width="100%"
        ></v-img>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-img
            :src="require('@/assets/imgs/desing/aboutsubbanner2.jpeg')"
            class="grey lighten-2"
            height="400"
            width="100%"
        ></v-img>
      </v-col>
    </v-row>
    <br>

    <div>
      <span v-for="(line, index) in $t('straborn_story')" :key="'story-' + index"
            style="display: block; line-height: 1.4;">
        {{ line }}
    </span>
      <br>
      <span v-for="(line, index) in $t('straborn_coffee_intro')" :key="'intro-' + index"
            style="display: block; line-height: 1.4;">
        {{ line }}
    </span>
    </div>

  </v-container>
</template>
<script>
import CustomBanner from '@/components/core/CustomBanner.vue'

export default {
  components: {CustomBanner},
}
</script>
<style lang="">

</style>