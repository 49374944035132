export const LANGUAGE_TEXT = {
    tr: {
        welcome: "Hoş geldiniz",
        about_us: "HAKKIMIZDA",
        about_us_lower: "Hakkımızda",
        contact: "İletişim",
        coffee_menu: "Kahve Menüsü",
        order_now: "Şimdi Sipariş Ver",
        location: "Konum",
        opening_hours: "Çalışma Saatleri",
        follow_us: "Bizi Takip Edin",
        learn_more: "Daha Fazla Bilgi",
        our_story: "Hikayemiz",
        quality_coffee: "Kaliteli Kahve",
        sustainability: "Sürdürülebilirlik",
        testimonials: "Müşteri Yorumları",
        privacy_policy: "Gizlilik Politikası",
        terms_conditions: "Şartlar ve Koşullar",
        careers: "Kariyer",
        blog: "Blog",
        shop_now: "Şimdi Alışveriş Yap",
        cart: "Sepet",
        checkout: "Ödeme",
        subscribe: "Abone Ol",
        search: "Ara",
        discover_more: "Daha Fazla Keşfet",
        customer_support: "Müşteri Desteği",
        faq: "Sıkça Sorulan Sorular",
        language: "Dil",
        home: "ANA SAYFA",
        strabon: "STRABON",
        strabon_lower: "Strabon",
        straborn: "STRABORN",
        straborn_slogan: "Muhteşem Kahve",
        find_your_coffee: "Kendi Kahveni Bul",
        enjoy_comfort: "Rahatlığın Tadını Çıkar",
        hot_beverages: "Sıcak İçecekler",
        coffee_latte_hot_drinks: "Kahve Latte Gibi Sıcak İçecekler",
        cold_beverages: "Soğuk İçecekler",
        refreshing_drinks: "Serinleten İçecekler",
        our_coffee: "Bizim Kahvemiz",
        coffee_and_comfort: "Kahve ve Rahatlık",
        best_coffee_experience: "En İyi Kahve Deneyimi",
        taste_the_difference: "Farkı Hissedin",
        fresh_roasted: "Taze Kavrulmuş Kahve",
        handcrafted_perfection: "El Yapımı Mükemmellik",
        explore_new_flavors: "Yeni Lezzetleri Keşfet",
        coffee_lovers_choice: "Kahve Tutkunlarının Tercihi",
        brewed_with_passion: "Tutkuyla Demlenen Kahve",
        invitation: "Davet",
        brand_mission_vision: "Marka Misyonu - Vizyonu",
        brand_mission_vision_upper: "MARKA MİSYONU - VİZYONU",
        join_our_team: "Ekibimizde yer almak ister misiniz?",
        gallery: "Galeri",
        menu: "Menü",
        our_story_about_us: "Hikayemiz - Hakkımızda",
        brand_mission: "MARKA MİSYONU",
        brand_vision: "MARKA VİZYONU",
        branches: "ŞUBELERİMİZ",
        branches_lower: "Şubelerimiz",
        address: [
            "Tel: 0312 229 19 49",
            "G.M.K. Bulvarı 103/12 Maltepe - Çankaya - ANKARA"
        ],
        straborn_story: [
            "Straborn Coffee özgür ruha inanan kahve tutkunu bir ekip tarafından “...Ye, iç, neşelen! Çünkü diğer şeyler bunun kadar değmez.”",
            "sözlerinin sahibi meşhur gezgin coğrafyacı Strabon’dan esinlenerek açıldı. Straborn ismi ile de özgürlüğe ve gezginliğe atıf yapıldı."
        ],
        straborn_coffee_intro: [
            "Straborn Coffee macerayı, gezginliği ve keşfetmeyi seven insanların buluştuğu, uzun ve keyifli sohbetler eşliğinde kahvelerini yudumladıkları kafelere dönüşüyor.",
            "Bizler içimizdeki bu özgür ruhu ve kahve tutkusunu misafirlerimize aktarmak ve daha dinamik hale getirmek için uğraştık, nihayetinde Straborn Coffee doğdu.",
            "Nitelikli kahvelerimiz, pastalarımız ve konsept tasarımlarımız ile bir kültürü var etmeye hazır hale geldik.",
            "Tüm inancımız ve heyecanımızla bu keyfi yaşamaya sizleri de davet ediyoruz.",
            "“Çünkü kuşkusuz rastlantılar insanların düşüncesi ve hareketlerinde çok rol oynar”.",
            "Strabon"
        ],
        straborn_coffee_mission: [
            "“Daha keyifli bir yaşam için güzel anılar ve ilham verici özlemler yaratmak” misyonuyla Straborn Coffee, mobil internet ve büyük veri teknolojisinin",
            "yeni perakende modelini tam olarak kullanmakta ve yüksek teknoloji ile derinlemesine iş birliği yapmaktadır.",
            "Çeşitli alanlarda kaliteli tedarikçileri ile yüksek kaliteli bir tüketici deneyimini müşterilerine sunmaktadır."
        ],
        straborn_coffee_vision: [
            "“Kaliteli kahveleri ile lezzeti ve enerjiyi misafirlerine aktarmak, nitelikli kahveyi gezginlerin, gezmeyi sevenlerin ve özgür ruhların her uğrak yerinde karşılarına çıkartarak bir kahve markası yaratmak”",
            "vizyonuyla yola çıkan Straborn Coffee, kahve severlerin günlük yaşamının bir parçası haline gelmeyi, ürün ve hizmetleriyle günlük yaşamlarının her alanına nüfuz etmeyi hedeflemektedir."
        ],
        straborn_coffee_services: [
            "Şu anda, Straborn Coffee müşterilerine yüksek kaliteli ürünler sunmak için ülke çapında ödüllü Baristaları ile içecek, yiyecek ve hızlı tüketim malları alanlarını kapsayan kendi ürün sistemini kurmuş, teknoloji vasıtası ile de yüksek kolaylık sağlayan hizmetleri bir araya getirmiştir."
        ],
        about_strabon: [
            "Gezgin, tarihçi, coğrafyacı ve filozof olarak da bilinen Strabon dünyanın ilk coğrafyacısı olarak kabul edilir.",
            "Strabon’un coğrafyası tarihsel bir özellik taşımakla birlikte insanın, kavimlerin ve imparatorlukların fiziki dünya ile olan ilişkilerini de belirtir.",
            "Sosyal yapıya her zaman değinmiştir."
        ],
        strabon_biography: [
            "Amasya ili sınırlarının içinde, Anadolu topraklarında M.Ö 63 yılında dünyaya gelmiştir.",
            "Amasya'dan ayrılıp Nil boyunca gezmiştir.",
            "Kendisi batıda Sardunya'ya, kuzeyde Karadeniz'den güneyde Etiyopya'nın sınırlarına kadar seyahat ettiğini söylemektedir.",
            "Daha sonra Roma şehrinde uzun yıllar geçirmiştir.",
            "Bu gezintilerinde tarih, coğrafya, felsefe konularında çalışmış, bilgi toplamış ve eserlerini yazmıştır.",
            "Bu eserlerden 17 ciltlik Geographumena veya Geographika (Coğrafya) adlı yapıtının büyük bölümü ve 43 ciltlik Historika Hypomnemata (Tarihi Hatıralar) adlı eseri günümüze kadar gelmiştir."
        ],
        straborn_coffee_quality: [
            "Nitelikli Arabica çekirdeklerinden meydana getirdiğimiz blendlerimizden oluşan leziz kahvelerimiz tadıma hazır şekilde sizleri bekliyor.",
            "",
            "Kahve çekirdeklerimizi sizler için en iyi kahve üretim bölgelerinden getiriyor ve her bir çekirdeğin Arabica çekirdeği olmasını sağlıyoruz.",
            "Kolombiya, Guatemala, Brezilya, Sumatra, Kenya ve Etiyopya’dan gelen kahvelerimizi profesyonel baristalarımız ile özenle harmanlıyor ve içime hazır hale getiriyoruz."
        ],
        join_as_barista: [
            "Bu büyük maceraya Barista olarak katılmak mı istiyorsunuz?",
            "Özgür, macera sever, yenilikçi, karşılıklı güven esası temel değerlerine odaklanan Straborn'da kusursuz bir kariyer yapmak için hemen bizimle irtibata geçin."
        ],
        professional_coffee_service: [
            "Büyük işletmeler ve orta-üst düzey ofisler, profesyonel kahve hizmeti için lütfen bizi arayınız."
        ]
    },
    en: {
        welcome: "Welcome",
        about_us: "ABOUT US",
        about_us_lower: "About Us",
        contact: "Contact",
        coffee_menu: "Coffee Menu",
        order_now: "Order Now",
        location: "Location",
        opening_hours: "Opening Hours",
        follow_us: "Follow Us",
        learn_more: "Learn More",
        our_story: "Our Story",
        quality_coffee: "Quality Coffee",
        sustainability: "Sustainability",
        testimonials: "Testimonials",
        privacy_policy: "Privacy Policy",
        terms_conditions: "Terms & Conditions",
        careers: "Careers",
        blog: "Blog",
        shop_now: "Shop Now",
        cart: "Cart",
        checkout: "Checkout",
        subscribe: "Subscribe",
        search: "Search",
        discover_more: "Discover More",
        customer_support: "Customer Support",
        faq: "FAQ",
        language: "Language",
        home: "HOME",
        strabon: "STRABO",
        strabon_lower: "Strabo",
        straborn: "STRABORN",
        straborn_slogan: "Amazing Coffee",
        find_your_coffee: "Find Your Coffee",
        enjoy_comfort: "Enjoy the Comfort",
        hot_beverages: "Hot Beverages",
        coffee_latte_hot_drinks: "Coffee Latte & Other Hot Drinks",
        cold_beverages: "Cold Beverages",
        refreshing_drinks: "Refreshing Drinks",
        our_coffee: "Our Coffee",
        coffee_and_comfort: "Coffee and Comfort",
        best_coffee_experience: "The Best Coffee Experience",
        taste_the_difference: "Taste the Difference",
        fresh_roasted: "Freshly Roasted Coffee",
        handcrafted_perfection: "Handcrafted Perfection",
        explore_new_flavors: "Explore New Flavors",
        coffee_lovers_choice: "Coffee Lovers' Choice",
        brewed_with_passion: "Brewed with Passion",
        invitation: "Invitation",
        brand_mission_vision: "Brand Mission - Vision",
        brand_mission_vision_upper: "BRAND MISSION - VISION",
        join_our_team: "Would you like to join our team?",
        gallery: "Gallery",
        menu: "Menu",
        our_story_about_us: "Our Story - About Us",
        brand_mission: "BRAND MISSION",
        brand_vision: "BRAND VISION",
        branches: "BRANCHES",
        branches_lower: "Branches",
        address: "",
        straborn_story: [
            "Straborn Coffee was founded by a team of coffee enthusiasts who believe in the free spirit. “...Eat, drink, and be merry! Because other things are not worth as much.”",
            "Inspired by these words of the famous traveler and geographer Strabo, Straborn was born. The name Straborn also makes a reference to freedom and travel."
        ],
        straborn_coffee_intro: [
            "Straborn Coffee is transforming into cafes where people who love adventure, travel, and exploration come together, enjoying their coffee alongside long and pleasant conversations.",
            "We have worked hard to share this free spirit and passion for coffee with our guests and to make it even more dynamic, and finally, Straborn Coffee was born.",
            "With our specialty coffees, pastries, and concept designs, we are ready to create a unique culture.",
            "With all our belief and excitement, we invite you to experience this joy with us.",
            "“For undoubtedly, coincidences play a big role in people's thoughts and actions.”",
            "Strabo"
        ],
        straborn_coffee_mission: [
            "With the mission of “Creating beautiful memories and inspiring aspirations for a more enjoyable life,” Straborn Coffee fully utilizes the new retail model of mobile internet and big data technology, collaborating deeply with high technology.",
            "It provides a high-quality consumer experience with premium suppliers across various fields."
        ],
        straborn_coffee_vision: [
            "“To deliver taste and energy to its guests with quality coffees, to bring specialty coffee to travelers, explorers, and free spirits at every stop they visit, creating a unique coffee brand” is the vision that Straborn Coffee was founded upon.",
            "Straborn Coffee aims to become an essential part of coffee lovers' daily lives and to integrate its products and services into every aspect of their routine."
        ],
        straborn_coffee_services: [
            "Currently, Straborn Coffee has established its own product system covering beverages, food, and fast-moving consumer goods, with award-winning baristas across the country to provide high-quality products to its customers.",
            "It has also integrated technology-driven services that offer high convenience."
        ],
        about_strabon: [
            "Strabo, known as a traveler, historian, geographer, and philosopher, is considered the world's first geographer.",
            "Strabo’s geography not only has a historical aspect, but also highlights the relationship between humans, tribes, and empires with the physical world.",
            "He always addressed social structures."
        ],
        strabon_biography: [
            "He was born in 63 BC in the lands of Anatolia, within the borders of Amasya.",
            "He left Amasya and traveled along the Nile.",
            "He stated that he traveled as far west as Sardinia, from the Black Sea in the north to the borders of Ethiopia in the south.",
            "Later, he spent many years in the city of Rome.",
            "During his travels, he studied history, geography, and philosophy, collected knowledge, and wrote his works.",
            "Of these works, a large part of the 17-volume Geographumena or Geographika (Geography) and the 43-volume Historika Hypomnemata (Historical Memoirs) have survived to this day."
        ],
        straborn_coffee_quality: [
            "Our delicious coffees, crafted from carefully blended high-quality Arabica beans, are waiting for you to taste.",
            "",
            "We source our coffee beans from the best coffee-producing regions and ensure that each bean is 100% Arabica.",
            "Our beans from Colombia, Guatemala, Brazil, Sumatra, Kenya, and Ethiopia are carefully blended by our professional baristas and prepared for a perfect coffee experience."
        ],
        join_as_barista: [
            "Do you want to join this great adventure as a Barista?",
            "Straborn focuses on freedom, adventure, innovation, and mutual trust as core values.",
            "Contact us now to build a perfect career at Straborn."
        ],
        professional_coffee_service: [
            "Large businesses and mid-to-upper level offices, please contact us for professional coffee service."
        ]
    },
    de: {
        welcome: "Willkommen",
        about_us: "ÜBER UNS",
        about_us_lower: "Über uns",
        contact: "Kontakt",
        coffee_menu: "Kaffee-Menü",
        order_now: "Jetzt bestellen",
        location: "Standort",
        opening_hours: "Öffnungszeiten",
        follow_us: "Folge uns",
        learn_more: "Mehr erfahren",
        our_story: "Unsere Geschichte",
        quality_coffee: "Qualitätskaffee",
        sustainability: "Nachhaltigkeit",
        testimonials: "Kundenmeinungen",
        privacy_policy: "Datenschutzrichtlinie",
        terms_conditions: "Geschäftsbedingungen",
        careers: "Karriere",
        blog: "Blog",
        shop_now: "Jetzt einkaufen",
        cart: "Warenkorb",
        checkout: "Zur Kasse",
        subscribe: "Abonnieren",
        search: "Suche",
        discover_more: "Mehr entdecken",
        customer_support: "Kundendienst",
        faq: "Häufig gestellte Fragen",
        language: "Sprache",
        home: "STARTSEITE",
        strabon: "STRABON",
        strabon_lower: "Strabon",
        straborn: "STRABORN",
        straborn_slogan: "Hervorragender Kaffee",
        find_your_coffee: "Finde Deinen Kaffee",
        enjoy_comfort: "Genieße den Komfort",
        hot_beverages: "HEISSE GETRÄNKE",
        coffee_latte_hot_drinks: "Kaffee Latte und andere heiße Getränke",
        cold_beverages: "KALTE GETRÄNKE",
        refreshing_drinks: "Erfrischende Getränke",
        our_coffee: "UNSER KAFFEE",
        coffee_and_comfort: "Kaffee und Gemütlichkeit",
        best_coffee_experience: "Das Beste Kaffeeerlebnis",
        taste_the_difference: "Schmecke den Unterschied",
        fresh_roasted: "Frisch gerösteter Kaffee",
        handcrafted_perfection: "Handgefertigte Perfektion",
        explore_new_flavors: "Neue Aromen Entdecken",
        coffee_lovers_choice: "Die Wahl der Kaffeeliebhaber",
        brewed_with_passion: "Mit Leidenschaft Gebrüht",
        invitation: "Einladung",
        brand_mission_vision: "Markenmission - Vision",
        brand_mission_vision_upper: "MARKENMISSION - VISION",
        join_our_team: "Möchten Sie Teil unseres Teams werden?",
        gallery: "Galerie",
        menu: "MENÜ",
        our_story_about_us: "UNSERE GESCHICHTE - ÜBER UNS",
        brand_mission: "MARKENMISSION",
        brand_vision: "MARKENVISION",
        instagram: "https://www.instagram.com/straborncoffeegermany",
        branches: "FILIALEN",
        branches_lower: "Filialen",
        address: "",
        straborn_story: [
            "Straborn Coffee wurde von einem Team von Kaffeeliebhabern gegründet, die an den freien Geist glauben. „...Iss, trink und sei fröhlich! Denn andere Dinge sind nicht so viel wert.“",
            "Inspiriert von diesen Worten des berühmten Reisenden und Geographen Strabon wurde Straborn gegründet. Der Name Straborn ist ebenfalls eine Hommage an Freiheit und Reisen."
        ],
        straborn_coffee_intro: [
            "Straborn Coffee verwandelt sich in Cafés, in denen sich Menschen treffen, die Abenteuer, Reisen und das Entdecken lieben und ihren Kaffee",
            "bei langen und angenehmen Gesprächen genießen.",
            "Wir haben hart daran gearbeitet, diesen freien Geist und die Leidenschaft für Kaffee an unsere Gäste weiterzugeben und ihn noch dynamischer zu gestalten.",
            "Schließlich wurde Straborn Coffee geboren.",
            "Mit unseren hochwertigen Kaffees, Kuchen und Konzeptdesigns sind wir bereit, eine einzigartige Kultur zu erschaffen.",
            "Mit all unserem Glauben und unserer Begeisterung laden wir Sie ein, dieses Vergnügen mit uns zu erleben.",
            "„Denn zweifellos spielen Zufälle eine große Rolle im Denken und Handeln der Menschen.“",
            "Strabon"
        ],
        straborn_coffee_mission: [
            "Mit der Mission, „Schöne Erinnerungen und inspirierende Sehnsüchte für ein angenehmeres Leben zu schaffen“, nutzt Straborn Coffee das neue Einzelhandelsmodell des mobilen Internets",
            "und der Big-Data-Technologie voll aus, arbeitet intensiv mit Hochtechnologie zusammen.",
            "Es bietet ein hochwertiges Konsumerlebnis mit erstklassigen Lieferanten in verschiedenen Bereichen."
        ],
        straborn_coffee_vision: [
            "„Mit hochwertigen Kaffees Geschmack und Energie an seine Gäste weiterzugeben, Spezialitätenkaffee für Reisende, Entdecker und freie Geister an jedem Ort bereitzustellen und eine einzigartige Kaffeemarke zu schaffen“ ist die Vision, mit der Straborn Coffee gegründet wurde.",
            "Straborn Coffee möchte ein unverzichtbarer Teil des Alltags von Kaffeeliebhabern werden und seine Produkte und Dienstleistungen in alle Bereiche ihres täglichen Lebens integrieren."
        ],
        straborn_coffee_services: [
            "Derzeit hat Straborn Coffee sein eigenes Produktsystem aufgebaut, das Getränke, Lebensmittel und schnelllebige Konsumgüter umfasst, mit preisgekrönten Baristas im ganzen Land, um seinen Kunden hochwertige Produkte anzubieten.",
            "Es hat auch technologiebasierte Dienstleistungen integriert, die hohen Komfort bieten."
        ],
        about_strabon: [
            "Strabon, bekannt als Reisender, Historiker, Geograph und Philosoph, gilt als der erste Geograph der Welt.",
            "Strabons Geographie hat nicht nur eine historische Bedeutung, sondern betont auch die Beziehung zwischen Menschen, Stämmen und Reichen zur physischen Welt.",
            "Er hat sich stets mit sozialen Strukturen befasst."
        ],
        strabon_biography: [
            "Er wurde 63 v. Chr. in den Ländern Anatoliens, innerhalb der Grenzen von Amasya, geboren.",
            "Er verließ Amasya und reiste entlang des Nils.",
            "Er erklärte, dass er bis nach Sardinien im Westen, vom Schwarzen Meer im Norden bis zu den Grenzen Äthiopiens im Süden gereist sei.",
            "Später verbrachte er viele Jahre in der Stadt Rom.",
            "Während seiner Reisen studierte er Geschichte, Geographie und Philosophie, sammelte Wissen und schrieb seine Werke.",
            "Von diesen Werken sind große Teile des 17-bändigen Geographumena oder Geographika (Geographie) und das 43-bändige Historika Hypomnemata (Historische Memoiren) bis heute erhalten geblieben."
        ],
        straborn_coffee_quality: [
            "Unsere köstlichen Kaffees, hergestellt aus sorgfältig gemischten hochwertigen Arabica-Bohnen, warten darauf, von Ihnen probiert zu werden.",
            "",
            "Wir beziehen unsere Kaffeebohnen aus den besten Anbaugebieten und stellen sicher, dass jede Bohne 100 % Arabica ist.",
            "Unsere Bohnen aus Kolumbien, Guatemala, Brasilien, Sumatra, Kenia und Äthiopien werden von unseren professionellen Baristas sorgfältig gemischt und für ein perfektes Kaffeeerlebnis vorbereitet."
        ],
        join_as_barista: [
            "Möchten Sie dieses große Abenteuer als Barista beginnen?",
            "Straborn konzentriert sich auf Freiheit, Abenteuer, Innovation und gegenseitiges Vertrauen als zentrale Werte.",
            "Kontaktieren Sie uns jetzt, um eine perfekte Karriere bei Straborn zu starten."
        ],
        professional_coffee_service: [
            "Große Unternehmen und gehobene Büros, bitte kontaktieren Sie uns für professionellen Kaffeegenuss."
        ]
    }
};