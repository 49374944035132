<template>
  <v-footer class="py-4" dark height="auto">
    <v-container class="mx-auto">
      <v-row align="center">

        <v-col cols="auto" class="position-relative">
          <base-btn
              href="https://www.instagram.com/straborncoffee"
              class="ml-0 mr-3 instagram-btn"
              color="primary"
              square
              target="_blank"
          >
            <v-icon class="instagram-icon">mdi-instagram</v-icon>
            <v-img
                src="@/assets/flags/tr.png"
                width="18"
                height="12"
                class="flag-icon"
            />
          </base-btn>
        </v-col>

        <v-col cols="auto" class="position-relative">
          <base-btn
              href="https://www.instagram.com/straborncoffeegermany"
              class="ml-0 mr-3 instagram-btn"
              color="primary"
              square
              target="_blank"
          >
            <v-icon class="instagram-icon">mdi-instagram</v-icon>
            <v-img
                src="@/assets/flags/de.png"
                width="18"
                height="12"
                class="flag-icon"
            />
          </base-btn>
        </v-col>

        <v-col cols="12" md="4">
          <h3 class="footer-title">
            <router-link to="/our-branches" class="footer-title-link">{{ $t('branches') }}</router-link>
          </h3>
        </v-col>

        <v-spacer/>

        <v-col cols="auto" class="d-flex justify-end align-center">
          <v-img
              :src="require('@/assets/imgs/footer_pay.png')"
              width="400"
              contain
          ></v-img>
        </v-col>

        <v-col cols="auto" class="d-flex align-center">
          <base-btn class="mr-0" square title="Go to top" @click="$vuetify.goTo(0)">
            <v-icon>mdi-chevron-up</v-icon>
          </base-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>
<script>
export default {
  name: 'CoreFooter',
};
</script>

<style scoped>

.instagram-btn {
  position: relative;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.instagram-icon {
  font-size: 32px;
}

.flag-icon {
  position: absolute;
  bottom: -18px;
  left: 50%;
  transform: translateX(-50%);
  width: 26px;
  height: 18px;
  border-radius: 2px;
  box-shadow: none;
  background-color: transparent;
  padding: 0px;
  border: none;
}

.footer-title {
  font-size: 18px;
  font-weight: bold;
  color: white;
  display: flex;
  align-items: center;
  height: 100%;
}

.footer-title-link {
  text-decoration: none;
  color: inherit;
  font-weight: bold;
}

.footer-title-link:hover {
  text-decoration: none;
  color: #f5c518;
}

.footer-links li {
  margin-bottom: 5px;
}

.footer-links a {
  color: white;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}

</style>