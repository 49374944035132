<template>
  <base-card dark>
    <v-img
        :src="require('@/assets/imgs/desing/banner.jpeg')"
        class="grey lighten-2"
        height="400"
        width="100%"
    >
      <v-row
          class="fill-height pa-3"
          align="center"
      >
        <v-col
            cols="12"
            md="8"
            offset-md="6"
        >
          <h1 class="display-2 font-weight-light pl-1">
            {{ $t('straborn') }}
          </h1>

          <h1 class="display-2 font-weight-light pl-1">
            {{ $t('straborn_slogan') }}
          </h1>

          <div class="subheading pl-2 mb-2">
            {{ $t('find_your_coffee') }}
          </div>

          <div class="subheading pl-2 mb-2">
            {{ $t('enjoy_comfort') }}
          </div>

          <div class="pl-2">
            <v-btn
                color="secondary"
                depressed
            >
              {{ $t('menu') }}
            </v-btn>
          </div>

        </v-col>
      </v-row>
    </v-img>
  </base-card>
</template>

<script>
export default {
  name: 'HomeBanner',
}
</script>
